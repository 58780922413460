
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FileUploader } from 'react-drag-drop-files';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG']; // Allowed file types

const AnswerForm = ({
  selectedQuestionForAns,
  onSubmitAnswerForm,
  onCancelAnswerForm,
  formatDateTimeFun,
  isSubmitDisabled,
}) => {
  const [answer, setAnswer] = useState('');
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState('');
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [lightBoxSrc, setLightBoxSrc] = useState('');
  const [errorFileUpload, setErrorFileUpload] = useState('');

  const onFilechangeHandler = (file) => {
    const isImage = fileTypes.includes(file[0].type.split('/')[1]?.toUpperCase());
    if (isImage) {
      setPhotos(prevState => (
        [...prevState, ...file]
      ));
      setErrorFileUpload(''); // Clear error if valid file is uploaded
    } else {
      setErrorFileUpload('Only image files are allowed!');
    }
  };

  const onChangeHanlder = (e) => {
    setAnswer(e.target.value);
    if (answer && answer.length > 0) {
      setError('');
    } else if (answer.length >= 100) {
      setError('');
    }
  };

  const lightBoxController = (e) => {
    setIsLightBoxOpen(true);
    setLightBoxSrc(e.target.src);
  };

  const onSubmitAnswerFormHandler = (quesId) => {
    const answerData = {
      quesId, quesAns: answer, quesPhoto: photos,
    };
    if (answer.length === 0 || answer === '') {
      setError('Please Enter Answer.');
    } else if (answer.length < 100) {
      setError('Answer must be minimum 100 characters');
    } else if (photos && photos.length > 5) {
      setErrorFileUpload('Cannot upload more than 5 images.');
    } else {
      onSubmitAnswerForm(answerData);
      setError('');
      setErrorFileUpload('');
    }
  };

  useEffect(() => {
    if (photos && photos.length <= 5) {
      setErrorFileUpload('');
    }
  }, [photos]);

  const removePhotos = (lastModifieds) => {
    const filteredPhotos = photos.length
    && photos.filter(photo => photo.lastModified !== lastModifieds);
    setPhotos(filteredPhotos);
  };

  const allPhotos = photos.length > 0 && photos.map(photo => (
    <div className="ask-me-anything-answer-form-file-section" key={photo.lastModifieds}>
      <div>
        <img src={URL.createObjectURL(photo)} alt="uploadedImg" onClick={lightBoxController} role="presentation" />
        <span>{photo.name}</span>
      </div>
      <button type="button" onClick={() => removePhotos(photo.lastModified)}><i className="fa fa-trash" /></button>
    </div>
  ));

  return (
    <>
      <div className="ask-me-anything-answer-form-container">
        <div className="ask-me-anything-answer-form-content">
          <p className="ask-me-anything-question-txt">{selectedQuestionForAns.question_text}</p>
          <span className="ask-me-anything-question-date">{formatDateTimeFun(selectedQuestionForAns.created_datetime)}</span>
        </div>
        <div className="ask-me-anything-answer-form-textarea">
          <textarea
            value={answer}
            onChange={e => onChangeHanlder(e)}
            placeholder="Type your answer here..."
          />
          {error && <p className="error">{error}</p>}
        </div>
        <div className="ask-me-anything-answer-form-file">
          <div className="ask-me-anything-answer-form-file-choose">
            {/* <input type="file" onChange={e =>
              onFilechangeHandler(e)} multiple accept="image/*" disabled={photos.length >= 5} /> */}
            <FileUploader handleChange={onFilechangeHandler} name="file" multiple accept="image/*" disabled={photos.length >= 5} label="Upload or drag & drop photo here." />
            <small>
              <sup>*</sup>
              A maximum of 5 images is allowed.
            </small>
            {errorFileUpload
              && (
                <p
                  className="error"
                  style={{
                    margin: '2px 0 0',
                    color: '#b70000',
                    fontSize: '14px',
                  }}
                >
                  {errorFileUpload}
                </p>
              )
            }
          </div>
          {allPhotos}
        </div>
        <div className="ask-me-anything-actions mt-4">
          <button type="submit" className="ask-me-anything-fill-btn mr-2" onClick={() => onSubmitAnswerFormHandler(selectedQuestionForAns.objid)} disabled={isSubmitDisabled}>Save</button>
          <button type="button" className="ask-me-anything-outline-btn" onClick={onCancelAnswerForm}>Cancel</button>
        </div>
      </div>
      {isLightBoxOpen && (
        <Lightbox
          className="Lightbox"
          mainSrc={lightBoxSrc}
          onCloseRequest={() => setIsLightBoxOpen(false)}
        />
      )
      }
    </>
  );
};

AnswerForm.defaultProps = {
  selectedQuestionForAns: null,
  isSubmitDisabled: false,
};
AnswerForm.propTypes = {
  selectedQuestionForAns: PropTypes.instanceOf(Object),
  onCancelAnswerForm: PropTypes.func.isRequired,
  onSubmitAnswerForm: PropTypes.func.isRequired,
  formatDateTimeFun: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
};

export default AnswerForm;
