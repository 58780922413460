import { connect } from 'react-redux';
import AskMeAnything from '../scenes/AskMeAnything';
import getAskMeAnyQuestions, { createAskMeAnyAnswers, createAskMeAnyAnswerMedia } from '../actions/actionAskMeAnything';

const mapStateToProps = (state) => {
  const {
    performer: { data: { characters = {} } = {} } = {},
  } = state;

  let perfCharacterObjid = '';
  if (characters.length > 0) {
    perfCharacterObjid = characters[0].objid;
  }
  return ({
    perfCharacterObjid,
  });
};

const mapDispatchToProps = dispatch => ({
  getAskMeAnyQuestions: data => dispatch(getAskMeAnyQuestions(data)),
  createAskMeAnyAns: data => dispatch(createAskMeAnyAnswers(data)),
  createAskMeAnyAnswerMedia: data => dispatch(createAskMeAnyAnswerMedia(data)),
});

const AskMeAnythingContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AskMeAnything);

export default AskMeAnythingContainer;
